/** @jsx jsx */
import { jsx } from "theme-ui"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import BlogListHome from "../components/blog-list-home"
import PortfolioHome from "../components/portfolio-list-home"
import Testimonials from "../components/testimonials"
import Customers from "../components/customers"
import Seo from "../components/seo"
import { getSrc, GatsbyImage } from "gatsby-plugin-image"

import "react-responsive-carousel/lib/styles/carousel.min.css"
import { Carousel } from "react-responsive-carousel"

export const pageQuery = graphql`
  query HomeQuery($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      frontmatter {
        video
        homeAlign
        title
        tagLine
        description
        homeSlide {
          sliderImage {
            childImageSharp {
              gatsbyImageData(
                layout: FULL_WIDTH
                breakpoints: [360, 620, 1240]
                placeholder: BLURRED
              )
            }
          }
          imageTitle
        }
        primaryCta {
          text
          url
        }
        customers {
          customersVisibility
          customerImages {
            customerName
            customerImage {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
              relativePath
              publicURL
            }
          }
        }
        testimonialsContainer {
          testimonialVisibility
          testimonials {
            description
            name
            jobRole
          }
        }
        videoAreImage
      }
    }
    portfolios: allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { template: { eq: "portfolio-page" } } }
    ) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            title
            date(formatString: "MMMM DD, YYYY")
            description
            align
            featuredImage {
              childImageSharp {
                gatsbyImageData(
                  layout: FULL_WIDTH
                  breakpoints: [360, 480, 960]
                  placeholder: BLURRED
                )
              }
            }
          }
        }
      }
    }
    posts: allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { template: { eq: "blog-post" } } }
    ) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            authors
            featuredImage {
              childImageSharp {
                gatsbyImageData(
                  layout: FULL_WIDTH
                  breakpoints: [360, 600]
                  placeholder: BLURRED
                )
              }
            }
          }
        }
      }
    }
  }
`

const HomePage = ({ data }) => {
  const { markdownRemark, portfolios, posts } = data // data.markdownRemark holds your page data
  const { frontmatter } = markdownRemark

  const primaryUrl = frontmatter.primaryCta.url
    ? frontmatter.primaryCta.url
    : ""
  const primaryText = frontmatter.primaryCta.text
    ? frontmatter.primaryCta.text
    : ""

  const customerImages = frontmatter.customers.customerImages
  const testimonials = frontmatter.testimonialsContainer.testimonials

  // Featured home
  const personalInfo = (
    <div sx={indexStyles.personalDetails}>
      <p sx={indexStyles.tagLine}>{frontmatter.tagLine}</p>
      <h1 sx={indexStyles.title}>{frontmatter.title}</h1>
      <p sx={indexStyles.description}>{frontmatter.description}</p>
      {frontmatter.primaryCta.url && frontmatter.primaryCta.text ? (
        <Link
          sx={{
            variant: "variants.button.primaryLink",
            fontSize: [2, 3],
            fontWeight: "heading",
            mr: 4,
          }}
          to={primaryUrl}
        >
          {primaryText} <span className="icon">&rarr;</span>
        </Link>
      ) : (
        ""
      )}
    </div>
  )

  const Homeslides = frontmatter.homeSlide.map((slide, index) => {
    const hasImage = getSrc(slide.sliderImage).includes("spacer.png")
      ? false
      : true
    const image = hasImage
      ? slide.sliderImage.childImageSharp.gatsbyImageData
      : ""
    const imageText = slide.imageTitle ? slide.imageTitle : ""
    return (
      <div key={"homeSlider" + index}>
        {hasImage && (
          <GatsbyImage
            image={image}
            alt={imageText}
            sx={indexStyles.slideImage}
            loading="eager"
          />
        )}
      </div>
    )
  })

  const sliderShow = (
    <div sx={indexStyles.sliderShow}>
      {frontmatter.homeSlide.length === 1 ? (
        <div>{Homeslides}</div>
      ) : (
        <Carousel
          autoPlay
          infiniteLoop
          swipeable={true}
          transitionTime={300}
          showArrows={false}
          showStatus={false}
          showThumbs={false}
        >
          {Homeslides}
        </Carousel>
      )}
    </div>
  )

  const video = frontmatter.video ? frontmatter.video : ""
  const selectVideoAreImage = (
    <div className="sliderImage">
      {frontmatter.videoAreImage === "Image" ? sliderShow : ""}
      {frontmatter.videoAreImage === "Video" ? (
        <div dangerouslySetInnerHTML={{ __html: video }} />
      ) : (
        ""
      )}
      {frontmatter.videoAreImage === "None" ? "" : ""}
    </div>
  )

  return (
    <Layout>
      <Seo />
      <div sx={indexStyles.homeBanner}>
        {frontmatter.homeAlign === "right" ? (
          <div sx={indexStyles.homeBannerLeft}>
            <div sx={indexStyles.orderOne}>{selectVideoAreImage}</div>
            <div sx={indexStyles.orderTwo}>{personalInfo}</div>
          </div>
        ) : (
          ""
        )}
        {frontmatter.homeAlign === "left" ? (
          <div sx={indexStyles.homeBannerRight}>
            {personalInfo}
            {selectVideoAreImage}
          </div>
        ) : (
          ""
        )}
        {frontmatter.homeAlign === "center" ? (
          <div sx={indexStyles.homeBannerCenter}>
            {personalInfo}
            {selectVideoAreImage}
          </div>
        ) : (
          ""
        )}
      </div>
      {frontmatter.customers.customersVisibility === "YES" ? (
        <Customers data={customerImages} />
      ) : (
        ""
      )}
      <PortfolioHome data={portfolios} />
      {frontmatter.testimonialsContainer.testimonialVisibility === "YES" ? (
        <Testimonials data={testimonials} />
      ) : (
        ""
      )}
      <BlogListHome data={posts} />
    </Layout>
  )
}

export default HomePage

const indexStyles = {
  homeBanner: {
    py: 5,
    bg: "home.background",
  },
  homeBannerLeft: {
    ".slide": {
      bg: "transparent",
    },
    ".control-dots": {
      ".dot": {
        boxShadow: "none !important",
        width: "16px",
        height: "16px",
      },
    },
    variant: "variants.container",
    display: "grid",
    gridTemplateColumns: ["1fr", "1fr", "1fr", "1fr 1fr"],
    px: ["20px", "20px", "40px"],
    alignItems: "center",
    justifyContent: "center",
    gap: 7,
  },
  orderOne: {
    order: ["2", "1"],
  },
  orderTwo: {
    order: ["1", "2"],
  },
  homeBannerRight: {
    ".slide": {
      bg: "transparent",
    },
    ".control-dots": {
      ".dot": {
        boxShadow: "none !important",
        width: "16px",
        height: "16px",
      },
    },
    variant: "variants.container",
    display: "grid",
    gridTemplateColumns: ["1fr", "1fr", "1fr", "1fr 1fr"],
    px: ["20px", "20px", "40px"],
    alignItems: "center",
    justifyContent: "center",
    gap: 7,
  },
  homeBannerCenter: {
    variant: "variants.container",
    px: ["20px", "20px", "40px"],
    textAlign: "center",
    p: {
      m: "0 auto",
      pt: 2,
    },
    ".sliderImage": {
      m: "auto 0px",
      mt: [0, 0, 7],
      maxWidth: "100%",
      width: "100%",
    },
  },
  sliderShow: {
    borderRadius: "12px",
    overflow: "hidden",
  },
  slideImage: {
    width: "100%",
    maxWidth: "100%",
    display: "block",
  },
  personalDetails: {
    maxWidth: "100%",
  },
  title: {
    m: 0,
    mb: [3, 4],
    p: 0,
    color: "home.headingColor",
    letterSpacing: "1px",
  },
  tagLine: {
    mt: 2,
    mb: [1],
    fontSize: 3,
    color: "home.tagColor",
  },
  description: {
    m: 0,
    lineHeight: "1.6",
    maxWidth: "75ch",
    color: "home.textColor",
    pb: [3, 4],
    fontSize: [4],
  },
  cta: {
    display: "flex",
  },
}
