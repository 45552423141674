/** @jsx jsx */
import { jsx } from "theme-ui"
import { Link } from "gatsby"

import BlogCard from "./blog-card"

export default function BlogListHome(props) {
  const data = props.data
  const posts = data.edges
    .filter(edge => !!edge.node.frontmatter.date)
    .map(edge => <BlogCard key={edge.node.id} data={edge.node} />)
  return <PostMaker data={posts} />
}

const PostMaker = ({ data }) => (
  <div sx={blogStyles.blogListHome}>
    <section sx={blogStyles.blogContainer}>
      <h2 sx={{ variant: "variants.pageHomeHead.title" }}>Blog</h2>
      <div sx={blogStyles.blogListContainer}>{data.slice(0, 3)}</div>
      {data.length > 3 ? (
        <Link sx={blogStyles.moreButton} to="/blog">
          See all posts
          <span className="icon">&rarr;</span>
        </Link>
      ) : (
        ""
      )}
    </section>
  </div>
)

const blogStyles = {
  blogListHome: {
    maxWidth: "100%",
    variant: "variants.section",
  },
  blogContainer: {
    variant: "variants.container",
  },
  blogListContainer: {
    display: "grid",
    gridTemplateColumns: [
      "repeat(1, 1fr)",
      "repeat(2, 1fr)",
      "repeat(2, 1fr)",
      "repeat(3, 1fr)",
    ],
    gap: ["30px", "30px", "48px", "48px", "64px"],
    mb: 7,
  },
  moreButton: {
    variant: "variants.moreButton",
  },
}
