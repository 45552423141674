/** @jsx jsx */
import { jsx } from "theme-ui"
import { getSrc, GatsbyImage } from "gatsby-plugin-image"

const Customers = props => {
  const data = props.data

  // const customerData = data.markdownRemark.frontmatter
  const customersLogo = data.map((customer, index) => {
    const hasImage = getSrc(customer.customerImage) ? true : false
    const customerImage = hasImage === true ? customer.customerImage.childImageSharp.gatsbyImageData : ""
    const CustomerName = customer.customerName ? customer.customerName : ""
    return (
      <div sx={customerStyles.customerImg} key={"CImg" + index}>
        {customerImage !== "" ? (
          <GatsbyImage
            image={customerImage}
            objectFit="cover"
            objectPosition="50% 50%"
            alt={CustomerName}
          />
        ) : (
          <div
            sx={customerStyles.logo}
            style={{
              mask:
                "url('" +
                customer.customerImage.publicURL +
                "') no-repeat center / contain",
              "-webkit-mask":
                "url('" +
                customer.customerImage.publicURL +
                "') no-repeat center / contain",
            }}
          />
        )}
      </div>
    )
  })

  return (
    <div sx={customerStyles.customerContainer}>
      <div sx={customerStyles.customers}>
        <div sx={customerStyles.customerImages}>{customersLogo}</div>
      </div>
    </div>
  )
}

export default Customers

const customerStyles = {
  customerContainer: {
    variant: "variants.section",
    bg: "customers.bgColor",
  },
  logo: {
    opacity: "0.4",
    height: "60px",
    bg: "customers.logoColor",
    mx: [3, 0],
  },
  customers: {
    variant: "variants.container",
  },
  customerImages: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexWrap: "wrap",
    gap: "32px",
  },
  customerImg: {
    width: "120px",
    py: 5,
    ".gatsby-image-wrapper": {
      width: "100%",
      mx: "auto",
      filter: "grayscale(1)",
      opacity: "0.9",
      textAlign: "center",
      transition: "filter .3s linear, opacity .3s linear",
    },
  },
}
