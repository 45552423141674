/** @jsx jsx */
import { jsx } from "theme-ui"
import { RiChatQuoteFill } from "react-icons/ri"

import "react-responsive-carousel/lib/styles/carousel.min.css"
import { Carousel } from "react-responsive-carousel"

const Testimonials = props => {
  const data = props.data
  const testimonial = data.map((item, index) => {
    return (
      <div key={"testimonial" + index}>
        {item.description ? (
          <p sx={testimonialsStyles.description}>{item.description}</p>
        ) : (
          ""
        )}

        {item.name || item.jobRole ? (
          <div sx={testimonialsStyles.testimonialData}>
            {item.name ? <h3>{item.name}</h3> : ""}
            {item.jobRole ? <span>{item.jobRole}</span> : ""}
          </div>
        ) : (
          ""
        )}
      </div>
    )
  })

  return (
    <div sx={testimonialsStyles.testimonial}>
      <div sx={testimonialsStyles.testimonials}>
        <div>
          <RiChatQuoteFill sx={testimonialsStyles.quoteicon} />
        </div>
        <div sx={testimonialsStyles.testimonialDetails}>
          <Carousel
            autoPlay
            infiniteLoop
            transitionTime={500}
            showStatus={false}
            showThumbs={false}
            showIndicators={false}
          >
            {testimonial}
          </Carousel>
        </div>
      </div>
    </div>
  )
}

export default Testimonials

const testimonialsStyles = {
  testimonial: {
    maxWidth: "100%",
    width: "100%",
    variant: "variants.section",
    bg: "testimonials.bgColor",
    ".slide": {
      bg: "transparent",
      textAlign: "left",
    },
    ".carousel .control-arrow.control-prev, .carousel .control-arrow.control-next": {
      bottom: "inherit",
      left: "inherit",
      right: "inherit",
      top: "0",
      opacity: "0.5 !important",
      "&:hover": {
        opacity: "1 !important",
      },
    },
    ".carousel .control-arrow.control-prev": {
      left: "0px",
    },
    ".carousel .control-arrow.control-next": {
      left: "30px",
    },
    ".carousel .control-prev.control-arrow::before": {
      borderRight: "8px solid",
      borderColor: "testimonials.logoColor",
      borderTopColor: "transparent",
      borderBottomColor: "transparent",
    },
    ".carousel .control-next.control-arrow::before": {
      borderLeft: "8px solid",
      borderColor: "testimonials.logoColor",
      borderTopColor: "transparent",
      borderBottomColor: "transparent",
    },
    ".carousel.carousel-slider .control-arrow:hover": {
      bg: "transparent",
    },
    ".carousel .control-arrow, .carousel.carousel-slider .control-arrow": {
      opacity: 0.8,
      "&:hover": {
        opacity: 1,
      },
    },
  },
  testimonials: {
    variant: "variants.container",
    px: ["20px", "20px", "40px"],
    display: "grid",
    gridTemplateColumns: ["1fr", "1fr", "1fr 2fr"],
    alignItems: "center",
    overflow: "hidden",
  },
  testimonialDetails: {
    maxWidth: "100%",
    overflow: "hidden",
  },
  quoteicon: {
    fontSize: ["300px", "400px", "400px", "500px"],
    color: "testimonials.logoColor",
    display: "inherit",
    ml: ["-20px", "-30px", "-30px", "-40px"],
    overflow: "hidden",
  },
  description: {
    maxWidth: "75ch",
    fontSize: [3, 4, 5],
    fontWeight: "light",
    lineHeight: 1.6,
    display: "block",
    color: "testimonials.textColor",
    m: 0,
    mr: 5,
    mt: 7,
    pb: 4,
  },
  testimonialData: {
    textTransform: "capitalize",
    h3: {
      fontSize: 4,
      my: 0,
      mt: [2, 3, 4],
      mb: 1,
      color: "global.headingColor",
    },
    span: {
      mt: 1,
      fontSize: 1,
      color: "global.mutedColor",
    },
  },
}
